const ArrowRightIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.7107 18.8536C15.5154 19.0488 15.1988 19.0488 15.0036 18.8536L14.2965 18.1464C14.1012 17.9512 14.1012 17.6346 14.2965 17.4393L19.2358 12.5L14.2965 7.56066C14.1012 7.3654 14.1012 7.04882 14.2965 6.85355L15.0036 6.14645C15.1988 5.95119 15.5154 5.95119 15.7107 6.14645L21.7107 12.1464C21.9059 12.3417 21.9059 12.6583 21.7107 12.8536L15.7107 18.8536Z"
			fill="currentColor"
		/>
		<rect
			x="20"
			y="11.55"
			width="2"
			height="18"
			rx="0.5"
			transform="rotate(90 20 11.55)"
			fill="currentColor"
		/>
	</svg>
)

export default ArrowRightIcon
